import { HeaderAppItem, HeaderAppNames, OrganizationItem } from '@xq/ui-kit';
import {
  iamGateway,
  userProfileGateway,
  generateHeaderAppItems,
  handleBackendError,
  wait
} from '@services';
import { LayoutDTOResponse } from '@xq/user-profile-gateway-frontend-client';
import { config } from '@config';

export interface UserLayoutData {
  firstName: string;
  lastName: string;
  organization: OrganizationItem;
  email: string;
  avatar: string;
  apps: HeaderAppItem[];
  languageIso2: string;
}

const mockResponse: UserLayoutData = {
  firstName: 'Simona',
  lastName: 'Mulkova',
  organization: {
    name: 'Exquance',
    uuid: '91e16833-6f0e-46fa-bfea-e817dfaaf5b61'
  },
  email: 'simona.mulkova@exquance.com',
  avatar: '',
  apps: [
    {
      name: HeaderAppNames.ModelTree,
      url: config.modelTreeFrontendUrl
    }
  ],
  languageIso2: 'ru'
};

export interface LayoutService {
  fetchData(): Promise<UserLayoutData>;
  authCheck(): Promise<void>;
  logout(): Promise<void>;
}

export class LayoutServiceApi implements LayoutService {
  async fetchData(): Promise<UserLayoutData> {
    try {
      const response: LayoutDTOResponse =
        await userProfileGateway.layoutControllerGetLayout();

      return {
        firstName: response?.firstName || '',
        lastName: response?.lastName || '',
        email: response?.email || '',
        avatar: response?.avatarUrl,
        languageIso2: response?.languageIso2,
        organization: {
          name: response?.organization?.name,
          uuid: response?.organization?.uuid
        },
        apps: generateHeaderAppItems(
          response?.isOrganizationAdmin,
          response?.isXQAdmin
        )
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      await iamGateway.authControllerCheck();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      await iamGateway.authControllerLogout();
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}

export class LayoutServiceMock implements LayoutService {
  async fetchData(): Promise<UserLayoutData> {
    try {
      /* api call */
      await wait(1000);
      return mockResponse;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      /* api call */
      await wait(10);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      /* api call */
      await wait(10);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}
