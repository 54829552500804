import { LicenseApp, SelectItem } from '@xq/ui-kit';
import { BackendLang } from '@interfaces';
import {
  appNameMapperByUUID,
  wait,
  handleBackendError,
  userProfileGateway
} from '@services';
import {
  UserSettingsDTOResponse,
  UserLicenseDTOResponse,
  AllApiUserSettingsControllerPostUserSettingsRequest
} from '@xq/user-profile-gateway-frontend-client';
import { FileSaveLinkDTOResponse } from '@xq/user-profile-gateway-frontend-client';
import { uploadFileBySignedLink } from '@services/file';

interface Licence {
  app: LicenseApp;
  /** License description */
  describingText: string;
  /** Selected role */
  selectedRole: SelectItem;
  /** Is it a trial version */
  isTrial?: boolean;
  /** Expiry date if trial */
  expiryDate?: string;
  /** Licence type (Investment Management / valuation / pro) */
  licenceType?: string;
}

export interface UserSettingsData {
  email: string;
  lang: string;
  languages: SelectItem[];
  notification: boolean;
  organization: string;
  licenses: Licence[];
  fromAzure: boolean;
}

export interface UserUpdatedSettings {
  firstName: string;
  lastName: string;
  notification: boolean;
  lang: string;
}

const mockResponse: UserSettingsData = {
  lang: 'ru',
  email: 'simona.mulkova@exquance.com',
  notification: true,
  languages: [
    { value: 'en', label: 'English' },
    { value: 'fi', label: 'Finnish' },
    { value: 'ru', label: 'Russian' }
  ],
  organization: 'Exquance',
  fromAzure: false,
  licenses: [
    {
      app: 'model-tree',
      licenceType: 'Investment',
      describingText:
        'Ultimate all-in-one financial modelling and reporting platform for property investment management and valuation.',
      expiryDate: '2024-01-31T21:00:00.000Z',
      isTrial: true,
      selectedRole: {
        label: 'Valuator',
        value: 'Valuator'
      }
    },
    {
      app: 'fundy',
      describingText:
        'Platform where you calculate, track and report about property fund’s daily operations.',
      selectedRole: {
        label: 'Valuator',
        value: 'Valuator'
      }
    }
  ]
};

export interface SettingsService {
  fetchData(): Promise<UserSettingsData>;

  save(settings: UserUpdatedSettings): Promise<void>;

  uploadAvatar(file: File): Promise<void>;
}

export class SettingsServiceApi implements SettingsService {
  async fetchData(): Promise<UserSettingsData> {
    try {
      const response: UserSettingsDTOResponse =
        await userProfileGateway.userSettingsControllerGetUserSettings();
      return {
        email: response?.email,
        lang: response?.languageIso2,
        notification: response?.receiveReleaseNotification,
        organization: response?.organizationName,
        fromAzure: response?.fromAzure,
        licenses: response?.licenses?.map((licence: UserLicenseDTOResponse) => {
          return {
            app: appNameMapperByUUID(licence?.licenseProduct?.uuid)
              ?.name as LicenseApp,
            licenceType: licence?.typeName,
            describingText: licence?.licenseProduct?.description,
            expiryDate: licence?.expireAt?.toISOString(),
            isTrial: licence?.isTrial,
            selectedRole: {
              label: licence?.roleName,
              value: licence?.roleName
            }
          };
        }),
        languages: response?.allowedLanguages?.map(
          (lang: BackendLang): SelectItem => {
            return { value: lang.iso2, label: lang.name };
          }
        )
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(settings: UserUpdatedSettings): Promise<void> {
    try {
      const params: AllApiUserSettingsControllerPostUserSettingsRequest = {
        userSettingsDTORequestUpdate: {
          firstName: settings.firstName,
          lastName: settings.lastName,
          receiveReleaseNotification: settings.notification,
          languageIso2: settings.lang
        }
      };

      await userProfileGateway.userSettingsControllerPostUserSettings(params);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async uploadAvatar(file: File): Promise<void> {
    try {
      const fileSaveLink: FileSaveLinkDTOResponse =
        await userProfileGateway.userSettingsControllerGetUploadUserAvatarUrl({
          fileInfoDTORequest: {
            byteSize: file.size,
            name: file.name
          }
        });

      await uploadFileBySignedLink(fileSaveLink, file);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

export class SettingsServiceMock implements SettingsService {
  async fetchData(): Promise<UserSettingsData> {
    try {
      /* api call */
      await wait(1000);
      return mockResponse;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(settings: UserUpdatedSettings): Promise<void> {
    try {
      /* api call */
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async uploadAvatar(file: File): Promise<void> {
    try {
      /* api call */
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.uploadAvatar.name
      );
    }
  }
}
