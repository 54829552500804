import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import cn from 'classnames';
import { config } from '@config';
import {
  Col,
  Row,
  Toggle,
  Typography,
  Input,
  Icon,
  Button,
  UserLicenseCard,
  Link,
  offsets,
  colors,
  display,
  LicenseApp,
  Avatar,
  SelectItem,
  Select,
  common,
  openStatusNotification,
  Chevron,
  flex
} from '@xq/ui-kit';
import {
  SettingsService,
  SettingsServiceApi,
  UserSettingsData,
  UserUpdatedSettings
} from './settings-service';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextData } from '@context';
import { errorLog } from '@services/logger';
import { iamGateway, submitForm } from '@services';
import { getStatusNotificationTranslations } from '@services/notifications';
import { NavLink } from 'react-router-dom';

export const Settings: FC = () => {
  const userContext: UserContextData = useContext(UserContext);
  const service: SettingsService = new SettingsServiceApi();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const currentLanguage = () => {
    let language = userData?.lang;
    if (!language) {
      language = config.defaultLang;
    }
    return userData?.languages?.find((el) => el.value === language);
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserSettingsData>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [lang, setLang] = useState<SelectItem>(currentLanguage());
  const [fromAzure, setFromAzure] = useState<boolean>(null);
  const [notification, setNotification] = useState<boolean>(false);

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setUserData(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `fetchData in settings page`);
    }
  }

  const initValues = () => {
    setFirstName(userContext?.firstName || '');
    setLastName(userContext?.lastName || '');
    setAvatar(userContext?.avatar || '');
    setNotification(userData?.notification || false);
    setFromAzure(userData?.fromAzure);
    setLang(currentLanguage());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    initValues();
  }, [userData, userContext]);

  useEffect(() => {
    if (lang?.value) {
      i18n.changeLanguage(lang.value);
    }
  }, [lang]);

  const fullName = `${firstName} ${lastName}`;

  async function save() {
    setLoading(true);

    /* todo send only changed values */
    const settings: UserUpdatedSettings = {
      firstName,
      lastName,
      notification,
      lang: lang?.value
    };

    try {
      await service.save(settings);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      userContext.setFirstName(firstName);
      userContext.setLastName(lastName);

      if (lang?.value) {
        window.localStorage.setItem(config.localStorageLang, lang.value);
      }
      await iamGateway.authControllerCheck({ xqUserMetaRefresh: 'true' });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setLoading(false);
    }
  }

  async function uploadAvatar(file: File) {
    try {
      await service.uploadAvatar(file);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fileUploadedSuccessfully'),
        status: 200
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setLoading(false);
    }
  }

  const cancel = () => {
    initValues();
  };

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Typography className={offsets['mb-40']} variant="h2">
            {t('settings.userSettings')}
          </Typography>
        </Col>

        <Col col={10} className={styles['user-info']}>
          <Avatar
            image={avatar}
            name={fullName}
            size={'large'}
            onChange={(file) => uploadAvatar(file)}
          />

          <div>
            <div
              className={cn(
                display['d-flex'],
                flex['align-items-center'],
                offsets['mb-4']
              )}
            >
              <Typography element="div" variant="body-2">
                {fullName}
              </Typography>

              {fromAzure && (
                <Chevron
                  className={offsets['ml-8']}
                  backgroundColor={colors.blue50Color}
                >
                  Azure AD
                </Chevron>
              )}
            </div>

            <Typography
              element="div"
              className={cn(offsets['mb-8'], common['no-text-transform'])}
              variant="body-1"
            >
              {userData?.email}
            </Typography>
            <Typography
              element="div"
              variant="body-1"
              className={common['no-text-transform']}
            >
              {userData?.organization}
            </Typography>
          </div>
        </Col>

        <Col col={10} sm={6} md={3}>
          <form onSubmit={submitForm}>
            <Input
              className={offsets['mb-40']}
              label={t('settings.firstName')}
              value={firstName}
              onChange={(value) => setFirstName(String(value))}
              disabled={isLoading || fromAzure}
            />
            <Input
              className={offsets['mb-40']}
              label={t('settings.lastName')}
              value={lastName}
              onChange={(value) => setLastName(String(value))}
              disabled={isLoading || fromAzure}
            />
            <Select
              className={offsets['mb-40']}
              label={t('settings.localization')}
              items={userData?.languages}
              onChange={setLang}
              disabled={isLoading}
              selected={lang}
              isLoading={isLoading}
            />
            <div className={display['d-flex']}>
              <Typography className={offsets['mr-12']} variant="body-1">
                {t('settings.emailNotification')}
              </Typography>
              <Toggle checked={notification} onChange={setNotification} />
            </div>
            <Link
              type={'button'}
              NavLink={NavLink}
              disabled={fromAzure}
              className={cn(display['d-block'], offsets['my-40'])}
              to={`/change-password`}
              isLocalRoute={true}
            >
              {t('common.changePassword')}
            </Link>

            <div className={styles.buttons}>
              <Button
                buttonType={'submit'}
                onClick={save}
                className={offsets['mr-20']}
                isLoading={isLoading}
              >
                {t('common.save')}
              </Button>
              <Button onClick={cancel} type="secondary" isLoading={isLoading}>
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>

        <Col col={1}></Col>
        <Col col={10} sm={6} md={3}>
          <Typography
            color={colors.gray60Color}
            element="div"
            variant="system-heading"
            className={offsets['mb-10']}
          >
            {userData?.licenses?.length > 0
              ? t('settings.licenses')
              : t('settings.noLicenses')}
          </Typography>
          <div className={offsets['mb-60']}>
            {(!userData?.licenses || userData?.licenses?.length === 0) && (
              <Icon
                className={cn(display['d-block'], offsets['mb-6'])}
                name="inbox"
                size="xxl"
                color="light-grey"
              />
            )}

            {userData?.licenses &&
              userData?.licenses?.map((license, key) => {
                return (
                  <UserLicenseCard
                    key={key}
                    isLoading={isLoading}
                    className={offsets['mb-20']}
                    app={license?.app as LicenseApp}
                    description={license.describingText}
                    expiryDate={
                      license.expiryDate && new Date(license.expiryDate)
                    }
                    licenseType={license?.licenceType}
                    isTrial={license.isTrial}
                    selectedRole={license.selectedRole}
                    translations={{
                      expiryDate: t('settings.expiryDate'),
                      role: t('settings.role'),
                      trialVersion: t('settings.trialVersion')
                    }}
                  />
                );
              })}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

Settings.displayName = 'Settings';
