import { TFunction } from 'i18next';
import { XQInvalidRequestMetaError } from '@xq/user-profile-gateway-frontend-client';

export const handleTranslationErrorFromStatus = (
  t: TFunction,
  error: XQInvalidRequestMetaError
): string => {
  switch (error?.error) {
    case 'IAM_USER_CHANGE_PASSWORD_ERROR': {
      return t('changePassword.invalidOldPassword');
    }
    default: {
      break;
    }
  }
};
