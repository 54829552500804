import React, { useState, FC, useEffect, useMemo, Fragment } from 'react';
import {
  LayoutContainer,
  NotificationContainer,
  Header,
  HeaderApp,
  NavItem,
  offsets,
  HeaderAppNames,
  openStatusNotification
} from '@xq/ui-kit';
import { Routes, Route, NavLink } from 'react-router-dom';
import { Settings, ChangePassword } from '@pages';
import { useTranslation } from 'react-i18next';
import {
  LayoutService,
  LayoutServiceApi,
  UserLayoutData
} from './layout-service';
import { UserContext } from '@context';
import styles from './Layout.module.scss';
import { getStatusNotificationTranslations } from '@services/notifications';
import { redirectToSSOLoginPage } from '@services';

interface LayoutProps {}

const currentApp: HeaderApp = HeaderAppNames.Account;

export const Layout: FC<LayoutProps> = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const service: LayoutService = new LayoutServiceApi();

  async function logout() {
    try {
      await service.logout();
      redirectToSSOLoginPage();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const [userData, setUserData] = useState<UserLayoutData>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);

  const fullName = useMemo(() => {
    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  }, [firstName, lastName]);

  const [userContextData, setUserContextData] = useState({
    firstName,
    lastName,
    avatar,
    setFirstName,
    setLastName,
    setAvatar
  });

  const initValues = () => {
    if (userData) {
      setFirstName(userData?.firstName);
      setLastName(userData?.lastName);
      setAvatar(userData?.avatar);
      setUserContextData({
        ...userContextData,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        avatar: userData?.avatar
      });
    }
  };

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setUserData(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        message: t('notifications.fetchDataError'),
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function authCheck() {
    try {
      await service.authCheck();
      setIsAuthChecked(true);
    } catch (error) {
      setIsAuthChecked(false);
      const status = error.status;
      if (status === 401) {
        redirectToSSOLoginPage();
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    }
  }

  useEffect(() => {
    if (userData?.languageIso2) {
      i18n.changeLanguage(userData?.languageIso2);
      if (localStorage.getItem('lang') !== userData?.languageIso2) {
        localStorage.setItem('lang', userData?.languageIso2);
      }
    }
  }, [userData?.languageIso2]);

  useEffect(() => {
    authCheck().then(() => {
      fetchData();
    });
  }, []);

  useEffect(() => {
    initValues();
  }, [userData]);

  const profileLinks: NavItem[] = [
    {
      name: t('settings.userSettings'),
      path: '/',
      isActive: true
    }
  ];

  return (
    <Fragment>
      {isAuthChecked && (
        <div className={styles.layout}>
          <Header
            className={styles.header}
            apps={userData?.apps}
            avatar={avatar}
            organizations={[userData?.organization]}
            currentOrganizationId={userData?.organization.uuid}
            name={fullName}
            currentApp={currentApp}
            NavLink={NavLink}
            profileLinks={profileLinks}
            onSignOut={logout}
            translations={{
              signOut: t('routes.signOut')
            }}
          />
          <main>
            <UserContext.Provider value={userContextData}>
              <LayoutContainer className={offsets['mt-60']}>
                <Routes>
                  <Route path="" element={<Settings />} />
                  <Route path="change-password" element={<ChangePassword />} />
                </Routes>
              </LayoutContainer>
            </UserContext.Provider>
          </main>
          <NotificationContainer />
        </div>
      )}
    </Fragment>
  );
};

Layout.displayName = 'Layout';
